export default [
  {
    path: '/DataQuality/ProductMaster',
    name: 'product-master',
    component: () => import('@/modules/data-quality/ProductMaster.vue'),
    meta: {
      action: 'read',
      resource: 'Sop',
    },
  },
]