export default [
  {
    path: '/visuals/database',
    name: 'visual-database',
    component: () => import('@/modules/visuals/VisualDatabase/VisualDatabase.vue'),
    meta: {
      action: 'read',
      resource: 'Sop',
    },
  },
  {
    path: '/visuals/prepare-summary',
    name: 'prepare-summary',
    component: () => import('@/modules/visuals/PrepareSummary.vue'),
    meta: {
      action: 'read',
      resource: 'Sop',
    },
  },
  {
    path: '/visuals/sop-simulation',
    name: 'sop-simulation',
    component: () => import('@/modules/visuals/SopSimulation/SopSimulation.vue'),
    meta: {
      action: 'read',
      resource: 'Sop',
    },
  },
  {
    path: '/visuals/cobertura',
    name: 'cobertura',
    component: () => import('@/modules/visuals/Cobertura.vue'),
    meta: {
      action: 'read',
      resource: 'Sop',
    },
  },
]