export default [
  {
    path: '/account-setting',
    name: 'account-setting',
    component: () => import('@/modules/account-setting/AccountSetting.vue'),
    meta: {
      action: 'read',
      resource: 'Sop',
      pageTitle: 'Configuracion de Cuenta',
      breadcrumb: [
        {
          text: 'Configuracion de Cuenta',
          active: true,
        },
      ],
    },
  },
  {
    path: '/users',
    name: 'users',
    component: () => import('@/views/Users.vue'),
    meta: {
      action: 'read',
      resource: 'Admin',
      pageTitle: 'Usuarios',
      breadcrumb: [
        {
          text: 'Users',
          active: true,
        },
      ],
    },
  },
  {
    path: '/users/edit/:id',
    name: 'users-edit',
    component: () => import('@/modules/users/UsersEdit.vue'),
    meta: {
      action: 'read',
      resource: 'Admin',
      pageTitle: 'Editar Usuario',
      breadcrumb: [
        {
          text: 'Users',
          to: '/users',
        },
        {
          text: 'Editar',
          active: true,
        },
      ],
    },
  },
]